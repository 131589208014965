import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Box,
  Typography,
  useMediaQuery,
  AppBar,
  Toolbar,
  useTheme,
  ButtonBase,
  Container,
  Grid,
  IconButton,
  List,
  Divider,
  ListItem,
  SwipeableDrawer,
  Link,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
// svgs
import { ReactComponent as LogoIcon } from "../svgs/logo.svg";
// icons
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useAlert } from "./Alert";
import { useLocalStorage } from "./LocalStorageContext";

const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      name
    }
  }
`;

const menuList = [
  {
    label: "關於我們",
    href: "/about",
  },
  {
    label: "產品類型",
    href: "/product-list/cards",
  },
  {
    label: "購物說明",
    href: "/guide",
  },
  {
    label: "聯絡我們",
    href: "/contact-us",
  },
];

export default function Layout({ children }) {
  return (
    <div>
      <div>
        <TopBar />
      </div>
      <div>{children}</div>
      <div>
        <FooterContent />
      </div>
    </div>
  );
}

function TopBar() {
  const LocalStorage = useLocalStorage();
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const history = useHistory();
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    menuList: {
      color: "#616161",
    },
    btnBox: {
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        position="sticky"
        style={{ backgroundColor: "#fff", boxShadow: "none" }}
      >
        <Toolbar
          style={{ padding: "0px", minHeight: "64px", position: "relative" }}
        >
          <Container
            maxWidth="xl"
            style={{ padding: isTablet ? "0px" : "10px", minHeight: "64" }}
          >
            {/* PC TopBar */}
            <Box
              style={{
                display: isTablet ? "none" : "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* logo */}
              <ButtonBase onClick={() => history.push("/")}>
                <LogoIcon
                  style={{ width: "100%", height: "64", padding: "5px" }}
                />
              </ButtonBase>
              <Box color="#fff">
                <Grid container spacing={2}>
                  {/* list */}
                  {menuList.map((item) => {
                    return (
                      <Grid item className={classes.btnBox}>
                        <Button
                          className={classes.menuList}
                          onClick={() => history.push(item.href)}
                        >
                          {item.label}
                        </Button>
                      </Grid>
                    );
                  })}
                  {!LocalStorage.getMemberToken() ? (
                    <Grid item className={classes.btnBox}>
                      <Button
                        className={classes.menuList}
                        onClick={() => history.push("/log-in")}
                      >
                        登入/註冊
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item className={classes.btnBox}>
                      <Button
                        className={classes.menuList}
                        onClick={() => {
                          LocalStorage.removeMemberToken();
                          LocalStorage.forceUpdate();
                        }}
                      >
                        登出
                      </Button>
                    </Grid>
                  )}
                  {/* cartIcon */}
                  <Grid item>
                    <IconButton
                      style={{ color: "#50A4DE" }}
                      onClick={() => history.push("../cart")}
                    >
                      <ShoppingCartIcon
                        style={{ width: "26px", height: "26px" }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* ANCHOR Mobile */}
            <Box display={isTablet ? "flex" : "none"}>
              <MobileNav />
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
  );
}

function MobileNav() {
  const LocalStorage = useLocalStorage();
  const theme = useTheme();
  const history = useHistory();
  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
  });
  const classes = useStyles();
  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={classes.list}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* drawer 內東西 */}
      <List>
        {/* back */}
        <ListItem>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {menuList.map((item, index) => (
          <ListItem button key={index}>
            <Typography
              buttonBottom
              style={{ color: theme.palette.text.main, margin: "0.3em 0" }}
              onClick={() => history.push(item.href)}
            >
              {item.label}
            </Typography>
          </ListItem>
        ))}
        {!LocalStorage.getMemberToken() && (
          <ListItem button>
            <Typography
              buttonBottom
              style={{ color: theme.palette.text.main, margin: "0.3em 0" }}
              onClick={() => history.push("/log-in")}
            >
              登入/註冊
            </Typography>
          </ListItem>
        )}
        <ListItem button>
          <Typography
            buttonBottom
            style={{ color: theme.palette.text.main, margin: "0.3em 0" }}
            onClick={() => history.push("../cart")}
          >
            購物車
          </Typography>
        </ListItem>
      </List>
    </div>
  );
  return (
    // drawer 開關
    <Box style={{ display: "flex" }}>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuIcon style={{ color: "black" }} />
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
      <Box>
        <LogoIcon
          style={{
            width: "140px",
            height: "64",
            cursor: "pointer",
            position: "absolute",
            left: "50%",
            top: 0,
            transform: " translate(-50%, 0%)",
          }}
          onClick={() => history.push("/")}
        />
      </Box>
      {/* empty grid div */}
      <div style={{ opacity: 0 }}></div>
    </Box>
  );
}

// ANCHOR Footer
function FooterContent() {
  const Alert = useAlert();
  const theme = useTheme();
  const LocalStorage = useLocalStorage();
  const isTablet = useMediaQuery("(max-width:960px)");
  const isPhone = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const useStyles = makeStyles(() => ({
    footerBox: {
      borderTop: `4px solid ${theme.palette.primary.main}`,
      borderBottom: `4px solid ${theme.palette.primary.main}`,
      marginBottom: "2em",
    },
  }));
  const classes = useStyles();

  const { data, loading } = useQuery(GET_CATEGORIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      return Alert.notify(error.message.replace("GraphQL error: ", ""));
    },
  });

  const categoryList = useMemo(() => {
    if (Boolean(data)) {
      const newCategories = [];
      data.categories.forEach((item) => {
        newCategories.push({ ...item, path: `/product-list/${item.id}` });
      });
      return {
        title: "產品",
        list: newCategories,
      };
    } else {
      return { title: "", list: [] };
    }
  }, [data]);
  const footerList = [
    {
      title: "會員",
      list: [
        {
          label: "購物說明",
          href: "/guide",
        },
        {
          label: "退換貨規則",
          href: "/return-exchange",
        },
        {
          label: "隱私權保護政策",
          href: "/privacy",
        },
      ],
    },
    {
      title: "聯繫",
      list: [
        {
          label: "關於我們",
          href: "/about",
        },
        {
          label: "聯絡我們",
          href: "/contact-us",
        },
        {
          label: "FAQ",
          href: "/question",
        },
      ],
    },
  ];

  return (
    <div style={{ backgroundColor: "white" }} className={classes.footerBox}>
      <Container maxWidth="xl">
        <Box py={2} style={{ cplor: "#616161" }}>
          <Grid container alignItems="center" spacing={2}>
            {/* logo */}
            <Grid
              item
              md={4}
              container
              justifyContent={isPhone ? "center" : ""}
            >
              <LogoIcon
                style={{
                  width: isPhone ? "140px" : "250px",
                  cursor: "pointer",
                  padding: "10px",
                }}
                onClick={() => history.push("/")}
              />
            </Grid>
            <Grid container item md={6} justifyContent="space-around">
              <Grid item>
                {loading ? (
                  <Grid container justify="center">
                    <CircularProgress color="secondary" />
                  </Grid>
                ) : (
                  <Box display="flex" flexDirection="column">
                    <Box mb={1}>
                      <Typography
                        variant={isPhone ? "body1" : "h5"}
                        color="#616161"
                        style={{ fontWeight: "bold" }}
                      >
                        {categoryList.title}
                      </Typography>
                    </Box>
                    {categoryList.list.map((list) => {
                      return (
                        <Link
                          color="#616161"
                          underline="hover"
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push(list.path)}
                        >
                          <Typography
                            variant={isPhone ? "body2" : "body1"}
                            style={{ lineHeight: "2" }}
                          >
                            {list.name}
                          </Typography>
                        </Link>
                      );
                    })}
                  </Box>
                )}
              </Grid>
              {/* list */}
              {footerList.map((item) => {
                return (
                  <Grid item>
                    <Box display="flex" flexDirection="column">
                      <Box mb={1}>
                        <Typography
                          variant={isPhone ? "body1" : "h5"}
                          color="#616161"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                      {!LocalStorage.getMemberToken() &&
                        (item.title === "會員" ? (
                          <Link
                            color="#616161"
                            underline="hover"
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push("/log-in")}
                          >
                            <Typography
                              variant={isPhone ? "body2" : "body1"}
                              style={{ lineHeight: "2" }}
                            >
                              登入會員
                            </Typography>
                          </Link>
                        ) : null)}
                      {item.list.map((list) => {
                        return (
                          <Link
                            color="#616161"
                            underline="hover"
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push(list.href)}
                          >
                            <Typography
                              variant={isPhone ? "body2" : "body1"}
                              style={{ lineHeight: "2" }}
                            >
                              {list.label}
                            </Typography>
                          </Link>
                        );
                      })}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            {/* copy */}
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent={isTablet ? "center" : "flex-end"}
                mt={2}
              >
                <Typography variant={isPhone ? "caption" : "body2"}>
                  Copyright Ⓒ 2021 奇麗科技股份有限公司
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
