import React from "react";
import { Typography, Grid, makeStyles, Container } from "@material-ui/core";

export default function LaborDayEventPage() {
  const useStyles = makeStyles((theme) => ({
    box: {
      textAlign: "left",
      "& .MuiTypography-h4": {
        display: "inline-block",
        fontWeight: "bold",
        borderBottom: "3px solid #50A4DE",
        margin: "50px auto",
      },
      "& .MuiTypography-h6": {
        margin: "1em",
      },
      "& .MuiTypography-body1": {
        marginLeft: "3em",
        lineHeight: "2em",
      },
      marginBottom: "5em",
    },
  }));
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h4">活動辦法</Typography>
          <img
            src="/assets/images/Home/01.png"
            style={{ width: "100%", height: "400px", objectFit: "contain" }}
          />
          <Typography variant="h6">一、活動名稱：</Typography>
          <Typography variant="body1">勞動人民感恩回饋、好禮雙重送</Typography>
          <Typography variant="body1">第一重：全館消費享回饋5%</Typography>
          <Typography variant="body1">第二重：週週抽獎送免費點數</Typography>
          <Typography variant="h6">二、活動期間：</Typography>
          <Typography variant="body1">
            2024年5月1日~2024年5月31日23：59PM止（截止時間以官網系統主機時間為準）。
          </Typography>
          <Typography variant="h6">三、活動說明：</Typography>
          <Typography variant="body1">
            <ol>
              <li>
                第一重：全館消費享回饋5%。
                <br />
                於活動期間內，凡於百適買購物網上消費（不限金額）即可享有全站結帳95折優惠。
              </li>
              <li>
                第二重：週週抽獎送免費點數
                <br />
                於活動期間內，凡於百適買購物網上單筆消費金額滿10,000元以上，即可參加抽獎，每週抽出10名幸運兒，每位皆可獲得GASH點數100點。
                <br />
                單筆消費僅可抽獎乙次，僅具一次中獎資格，且不予重複中獎。
                <br />
                當週未中獎之名單，將自動累計至次週抽獎名單中。
                <br />
                若有中獎者放棄或遭取消中獎資格，剩餘名額將不進行補抽候補名額。
              </li>
            </ol>
          </Typography>
          <Typography variant="h6">四、抽獎日期：</Typography>
          <Typography variant="body1">
            2024年5月1日起，每週將抽出指定獎項，每週抽獎日期：
          </Typography>
          <Typography variant="body1">
            <ol>
              <li>第一週：2024/05/01至05/07消費，抽獎日期05/08</li>
              <li>第二週：2024/05/01至05/14消費，抽獎日期05/15</li>
              <li>第三週：2024/05/01至05/21消費，抽獎日期05/22</li>
              <li>第四週：2024/05/01至05/28消費，抽獎日期05/29</li>
              <li>第五週：2024/05/01至05/31消費，抽獎日期06/01</li>
            </ol>
          </Typography>
          <Typography variant="h6">五、得獎&獎項寄發通知：</Typography>
          <Typography variant="body1">
            <ol>
              <li>
                得獎名單將由本公司活動小組於抽獎後2個工作日內以電話通知已將GASH點數100點直接入點進會員帳戶中。
              </li>
              <li>
                如遇國定假日或天災等不可抗力因素，主辦單位及/或活動執行單位有權變更抽獎日期。
              </li>
            </ol>
          </Typography>
          <Typography variant="h6">六、活動參加資格以及注意事項：</Typography>
          <Typography variant="body1">
            <ol>
              <li>
                百適買【勞動人民感恩回饋、好禮雙重送活動】(以下稱本活動)係由奇麗科技股份有限公司(下稱主辦單位)所舉辦並執行，消費者一般個人資料(包括：姓名、地址、電話、email帳號)之蒐集、處理及利用並為連絡之用(以下簡稱「活動執行單位」)。消費者於參加本活動之同時，即同意接受本活動之活動辦法與注意事項之規範，並同意主辦單位或活動執行單位蒐集、處理及利用參加人之姓名、地址、電話以及email帳號等之一般個人資料，如不同意，恕無法參加活動。消費者如有違反，主辦單位或活動執行單位得取消其參加及得獎資格，並得就因此所產生之損害，向該消費者請求損害賠償。
              </li>
              <li>
                本活動如有任何因電腦、網路、電話、技術或其他不可歸責於主辦單位或活動執行單位之事由，而使參與本活動者所寄出之資料有延遲、遺失、錯誤、無法辨識或毀損所導致資料無效之情況，贈獎資格視同放棄，主辦單位或活動執行單位不負任何法律責任，參與本活動者或得獎者亦不得因此異議。
              </li>
              <li>
                活動參與者應保證填寫或提出之資料均為真實，且未冒用或盜用任何第三人之資料，如冒用或盜用任何第三人之資料之法律責任由活動參與者自行負責，概與主辦單位或活動執行單位無關。如查獲不實，主辦單位或活動執行單位除撤銷其得獎資格，並無提供所獲獎項之義務，若獎項業經配送，並有向中獎者請求懲罰性賠償金及相關行政費用與他損害賠償之權利。
              </li>
              <li>
                中獎者若查核發現有退貨紀錄，視同自動放棄中獎資格，主辦單位或活動執行單位將不另行通知。
              </li>
              <li>
                本活動僅限居住於台灣、金門、澎湖、馬祖地區之中華民國國民參加。
              </li>
              <li>
                消費者若以惡意之電腦程式或其他明顯違反活動公平性之方式，意圖混淆或影響活動結果者，一經主辦單位或活動執行單位查覺，可立即取消消費者之得獎資格，並可追回獎品。
              </li>
              <li>
                本活動獎項GASH點數100點，中獎人不得要求折抵現金、退換、轉售或折換其他物品。主辦單位或活動執行單位保有修改或變更活動方式及更換活動贈品之權利。本活動獎品如遇有缺貨或不可抗力之因素無法提供時，主辦單位或活動執行單位保留更換等值贈品或現金的權利。
              </li>
              <li>
                除上述注意事項，如有未盡事宜，主辦單位或活動執行單位擁有一切修改及取消本活動的權利而不作事前通知，亦有權對本活動之所有事宜做出解釋或裁決及變更活動獎品及辦法之權利，任何變更內容及其他詳細注意事項，則公告於活動網站。
              </li>
              <li>
                上述條款之準據法為台灣地區境內法律，相關爭議應以台北地方法院為第一審管轄法院。
              </li>
              <li>
                參加者參加本活動之同時，將視為同意接受本活動所有內容及細則之規範，如有違反本活動注意事項之行為，活動小組得取消其兌換資格，並對於任何破壞本活動之行為保留法律追訴權。
              </li>
              <li>
                百適買週年慶活動 活動小組客服信箱：
                <a href="mailto:besbuyservice@gmail.com">
                  besbuyservice@gmail.com
                </a>
                （服務時間週一至週五10:00~18:00，週六日及國定例假日等非一般正常工作天，恕不提供服務），活動詳情以此活動網頁公告為準。
              </li>
            </ol>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
