import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useTheme,
  Typography,
  InputLabel,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { ReactComponent as LogoIcon } from "../svgs/logo.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useAlert } from "../components/Alert";
import LoadingModal from "../components/LoadingModal";
import {
  emailPattern,
  verifyPattern,
  passwordPattern,
  mobilePattern,
} from "../components/unit";

const SEND_VERIFICATIONCODE = gql`
  mutation sendVerificationCode($mobile: String!) {
    sendVerificationCode(mobile: $mobile)
  }
`;

const REGISTER = gql`
  mutation register($userInput: UserInput!) {
    register(userInput: $userInput) {
      id
      token
    }
  }
`;

export default function RegisterPage() {
  const Alert = useAlert();
  const isPhone = useMediaQuery("(max-width:500px)");
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    box: {
      height: "100vh",
      backgroundColor: "white",
    },
    input: {
      height: "40px",
      padding: "0px",
      backgroundColor: "white",
    },
    labeltext: {
      color: "black",
      marginTop: "10px",
      marginBottom: "10px",
      fontSize: "14px",
      fontWeight: 400,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const { handleSubmit, control, getValues } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      username: "",
      password: "",
      repassword: "",
      dateOfBirth: null,
      mobile: "",
      verificationCode: "",
      privacy: false,
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [verifying, setVerifying] = useState(false);

  function _sendVerificationCode() {
    if (!getValues("mobile")) {
      return Alert.notify("手機號碼未填寫！");
    }
    if (!mobilePattern.test(getValues("mobile"))) {
      return Alert.notify("手機號碼格式錯誤！");
    }
    sendVerificationCode({
      variables: { mobile: getValues("mobile") },
    });
  }

  const [sendVerificationCode, { loading }] = useMutation(
    SEND_VERIFICATIONCODE,
    {
      onCompleted({ sendVerificationCode }) {
        if (sendVerificationCode) {
          return Alert.notify("驗證碼發送成功！", () => setVerifying(true));
        } else {
          return Alert.notify("驗證碼發送失敗，請重新嘗試！");
        }
      },
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    }
  );

  function _submit(data) {
    const userInput = {
      ...data,
    };
    delete userInput.privacy;
    delete userInput.repassword;
    register({ variables: { userInput } });
  }
  const [register, { loading: registerLoading }] = useMutation(REGISTER, {
    onCompleted({ register }) {
      if (register) {
        return Alert.notify("註冊成功！", () => history.replace("/"));
      } else {
        return Alert.notify("註冊失敗，請重新嘗試！");
      }
    },
    onError(error) {
      return Alert.notify(error.message.replace("GraphQL error: ", ""));
    },
  });

  return (
    <Grid>
      <LoadingModal loading={loading || registerLoading} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "auto", backgroundColor: "white" }}
      >
        <Grid container item spacing={1} xs={11} sm={6} md={5}>
          <Grid
            item
            xs={12}
            style={{
              display: isPhone ? "none" : "flex",
              justifyContent: "center",
              margin: "50px 0px",
            }}
          >
            <LogoIcon style={{ width: "300px", height: "64px" }} />
          </Grid>
          <Grid
            item
            style={{ display: "flex", justifyContent: "center" }}
            xs={12}
          >
            <Typography
              variant="h4"
              color="primary"
              style={{ fontWeight: 700, marginTop: isPhone ? "20px" : "0px" }}
              gutterBottom
            >
              註冊會員
            </Typography>
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>姓名</InputLabel>
            <Controller
              name="fullName"
              control={control}
              rules={{ required: "姓名為必填" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入姓名"
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item alignItems="flex-start">
            <InputLabel className={classes.labeltext}>信箱</InputLabel>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "信箱為必填",
                pattern: {
                  value: emailPattern,
                  message: "信箱格式不正確！",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入信箱"
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>帳號</InputLabel>
            <Controller
              name="username"
              control={control}
              rules={{
                required: "請填寫帳號",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入帳號"
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>密碼</InputLabel>
            <Controller
              name="password"
              control={control}
              rules={{
                required: "密碼為必填",
                pattern: {
                  value: passwordPattern,
                  message: "密碼格式錯誤，需包含英文以及數字！",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入密碼"
                  type={showPassword ? "text" : "password"}
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          size="small"
                          onClick={() => setShowPassword((e) => !e)}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>再次輸入密碼</InputLabel>
            <Controller
              name="repassword"
              control={control}
              rules={{
                required: "未輸入密碼",
                validate: {
                  value: (value) =>
                    getValues("password") === value || "密碼不一致",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請再次輸入密碼"
                  type={showRePassword ? "text" : "password"}
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          size="small"
                          onClick={() => setShowRePassword((e) => !e)}
                        >
                          {showRePassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid container item>
            <InputLabel className={classes.labeltext}>生日</InputLabel>
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{ required: "生日為必填" }}
              render={({ field: { ref, ...other }, fieldState: { error } }) => (
                <TextField
                  type="date"
                  {...other}
                  error={error}
                  maxDate={new Date()}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>電話</InputLabel>
            <Controller
              name="mobile"
              control={control}
              rules={{
                required: "電話為必填",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入電話"
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <Button
                        color="primary"
                        variant="contained"
                        style={{
                          height: 40,
                          width: "45%",
                          marginLeft: "0.5em",
                          color: "#fff",
                        }}
                        onClick={_sendVerificationCode}
                        disabled={error}
                      >
                        電話驗證
                      </Button>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>驗證碼</InputLabel>
            <Controller
              name="verificationCode"
              control={control}
              rules={{
                required: "驗證碼未輸入",
                pattern: {
                  value: verifyPattern,
                  message: "驗證碼格式不正確！",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder={verifying ? "驗證碼" : "請先按下手機驗證"}
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  disabled={!verifying}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name="privacy"
              control={control}
              rules={{ required: "請同意使用者條款" }}
              render={({ field, fieldState: { error } }) => (
                <Grid container>
                  <Grid item xs={12} container alignItems="center">
                    <Checkbox
                      {...field}
                      style={{
                        color: theme.palette.secondary.main,
                      }}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                    <Typography
                      variant="body2"
                      style={{ letterSpacing: "1.2px", color: "black" }}
                    >
                      我已閱讀並同意
                    </Typography>
                    <PrivacyPolicy control={control} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      style={{ color: "#f44336", paddingLeft: "2.3em" }}
                    >
                      &nbsp;&nbsp;
                      {error?.message}
                      &nbsp;&nbsp;
                    </Typography>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid container item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit(_submit)}
              style={{ marginBottom: "50px", color: "#fff" }}
              fullWidth
            >
              註冊
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function PrivacyPolicy({ control }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const useStyles = makeStyles({
    ul: {
      letterSpacing: "1px",
      fontSize: "0.9em",
      "& li": {
        marginTop: "0.5em",
        lineHeight: "1.2",
      },
      "& > ul": {
        listStyle: "disc",
        "& > ul": {
          listStyle: "decimal",
        },
      },
    },
  });
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const contentData = [
    {
      label: "一、基本原則",
      li: [
        {
          label:
            "奇麗科技股份有限公司(以下稱本公司)非常重視顧客的隱私權及個人資料的保護，為了保護顧客隱私並遵循「個人資料保護法」及相關法令之規定，因此制定本隱私權保護政策，以避免顧客個人資料之遺失、洩漏、竊取、竄改等情事發生。本隱私權保護政策之內容如下供您參考。",
        },
      ],
    },
    {
      label: "二、個人資料保護方針",
      li: [
        {
          label:
            "保護顧客的個人隱私是本公司重要的經營理念，本公司將遵守以下個人資料保護方針來提供給顧客最優質的服務：",
        },
        {
          label:
            "BesBuy百適買點數卡販售網 (以下稱本販售網)向顧客蒐集而保有的個人資料以及本公司經由正當程序向第三方取得而保有的個人資料，將供本公司按原來向顧客說明之目的與範圍進行利用。本公司未經顧客同意之下，絕不會將其個人資料公開、提供給予任何與本販售網無關之第三人，或為特定目的以外之利用。請顧客在使用完本販售網所提供的各項服務功能後，務必記得登出帳戶，若是與他人共享電腦或使用公共電腦，切記要關閉瀏覽器視窗。",
        },
      ],
    },
    {
      label: "三、個人資料的蒐集、處理及利用方式",
      li: [
        {
          label: "1.蒐集目的",
        },
        {
          label:
            "本公司蒐集個人資料之目的在於本販售網之電子商務服務、商品銷售、契約管理、確認及履行與顧客之交易內容、商品之配送(物流)及付款等服務(包含信用卡等金融交易授權)、商品及服務之說明、廣告行銷、顧客及會員管理與服務(包含會員相關權益通知)、贈獎活動、調查、統計與研究分析、資訊與資料庫管理、其他契約相關業務(包含售後服務)及履行法定義務有所必要者。",
        },
        {
          label: "2.蒐集之個人資料類別",
        },
        {
          label: "本公司於網站內蒐集的個人資料包括：",
        },
        {
          label:
            "(1)基本資料(包含訂購人及收件人)：姓名、性別、出生年月日、電子郵件信箱、聯絡電話、行動電話、地址、身分證字號、會員帳號及密碼(如為會員)等資料。",
        },
        {
          label:
            " (2)帳務資料：支付方式、金融帳戶之號碼、信用卡帳號、交易帳戶號碼、訂單編號、訂單商品明細、消費金額及其他交易資料等資料。",
        },
        {
          label:
            "3.利用期間、地區、對象及方式(1)期間：顧客當事人要求停止使用或本販售網停止提供服務之日為止。",
        },
        {
          label: "(2)地區：顧客之個人資料將用於台灣地區。",
        },
        {
          label:
            "(3)利用對象及方式：顧客之個人資料蒐集除用於本販售網之客戶管理、客戶管理之檢索查詢等功能外，亦將利用於辨識身份、金流服務、物流服務、行銷廣宣等。本公司將不會向第三人揭露您的個人資料，但法令另有規定或經取得您書面同意者，不在此限。",
        },
        {
          label:
            "(4)顧客就個人資料之權利：本公司所蒐集個人資料之當事人，依個人資料保護法得對本公司行使以下權利：",
        },
        {
          label: "(A)查詢或請求閱覽。",
        },
        {
          label: "(B)請求製給複製本。",
        },
        {
          label: "(C)請求補充或更正。",
        },
        {
          label: "(D)請求停止蒐集、處理或利用。",
        },
        {
          label: "(E)請求刪除。",
        },
        {
          label:
            "如您欲行使上述權利，請與本公司客服連絡或是寄信至客服信箱：besbuyservice@gmail.com進行申請。客服人員將以適當的方式確認您是否確為當事人本人或有權代為行使權利之人，並於合理的期間及範圍內協助處理相關事宜。",
        },
        {
          label:
            "※若您查詢、請求閱覽個人資料或請求製給複製本，本公司得酌收必要成本費用。",
        },
      ],
    },
    {
      label: "四、資料安全",
      li: [
        {
          label:
            "為保障顧客的隱私及安全，本公司顧客的帳號資料會用密碼保護，只由經過授權的人員才能接觸您的個人資料，本公司會盡力以合理之技術及程序，保障所有個人資料之安全。",
        },
        {
          label:
            "您同意絕不為非法之目的或以非法方式使用本服務，與確實遵守中華民國相關法規及網際網路之國際慣例，並保證不得利用本服務從事侵害他人權益或違法之行為",
        },
      ],
    },
    {
      label: "五、Cookie之使用安全",
      li: [
        {
          label:
            "為了提供最佳的服務，本販售網會使用cookie技術，以便於提供會員所需要的服務，若您不接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高即可，但若顧客關閉cookie有可能導致網站某些功能無法正常執行。",
        },
      ],
    },
    {
      label: "六、未成年人保護",
      li: [
        {
          label:
            "本網站並非特別為未成年人/兒童設計，未成年人使用本網站時，若同意本販售網蒐集、利用其個人資訊時，應在法定代理人或監護人之同意下為之。法定代理人或監護人得隨時請求本公司停止特定帳號及其相關之個人資料之蒐集、處理及利用行為。",
        },
      ],
    },
    {
      label: "七、隱私權保護政策修訂",
      li: [
        {
          label:
            "本公司將因應法令修正、社會經濟環境變遷及科技進步之因素，隨時修訂本隱私權政策之內容，修訂後的條款將刊登於本販售網上。",
        },
        {
          label:
            "當您正在使用本網站時，本私隱政策的設置使用於 BESBUY 百適買 並且進一步的保護你提供給我們的任何資訊。 BESBUY 百適買 致力於保護您的隱私。在您運作這個網站相關資料與購物時，可能會進一步的要求您提供某些相關資訊，您可以根據標示的拒絕存取，放心並正常的使用，提供的個資; 網站將只根據此份隱私權聲明宣告部分運用。",
        },
        {
          label:
            "BESBUY 百適買 我們會隨時不定期的更新這個頁面中的隱私權政策，並符合政府與法令相關的條件施行 ( 使用中華民國法令之保障權益 )。您應該檢查此頁以確保你是否滿意其中的任何更改。",
        },
      ],
    },
    {
      label: "我們收集哪些資訊",
      li: [
        {
          label: "我們可能會收集以下資訊：",
        },
        {
          label: "您提供的名字。",
        },
        {
          label: "包括您的電子郵件地址的連絡人資訊。",
        },
        {
          label: "統計資訊，如郵遞區號、 愛好和興趣。",
        },
        {
          label: "有關客戶調查和或提供的其他相關資料。",
        },
        {
          label: "我們收集您的 cookies 的詳盡清單，請參閱 Cookies 清單 章節。",
        },
      ],
    },
    {
      label: "關於我們收集到的資訊",
      li: [
        {
          label:
            "我們需要該資訊以瞭解您購物的需求，為了提供您更好的服務和愉快的購物體驗，特別是出於以下原因：",
        },
        {
          label: "內部記錄保存。",
        },
        {
          label:
            "我們可能會使用這些資訊，以方便進一步改進我們的商品資訊和服務。",
        },
        {
          label:
            "我們可能會定期發送有關新產品的促銷電子郵件、特別優惠或其他我們認為您可能會發現的購物樂趣，並使用您提供的電子郵件地址寄送相關資訊。",
        },
        {
          label:
            "我們還可能使用您的資訊與您聯繫，以市場研究為目的。我們會透過電子郵件與您聯繫。我們會根據你的興趣和需求相關資訊來定制本網站相關活動內容。",
        },
      ],
    },
    {
      label: "交易安全",
      li: [
        {
          label:
            "我們會致力確保您的資訊安全。為了防止未經授權的訪問或披露，我們已在適當的商品物件、 電子系統閘道和管理程式上做好防範措施，以確保並保障及保護我們線上收集的資訊以及您的交易過程。",
        },
      ],
    },
    {
      label: "我們如何使用 cookie",
      li: [
        {
          label:
            "Cookie 是一個極小的檔案，是一個要求放置在您的電腦硬碟上的許可權。一旦你同意，該檔將添加在 cookie 可以説明分析網站流量，或讓你知道當你訪問一個特定的網站。Cookie 允許 web 應用程式回應你作為一個個體。Web 應用程式可以定制您的需求操作，喜歡和不喜歡通過收集和記憶您的首選項目相關的資訊。",
        },
        {
          label:
            "我們使用 cookie 流量控制，以確定哪些頁面正在使用。這可以説明我們分析網頁流量資料和改善我們的網站，以適應客戶的需求。我們僅將此資訊用於統計分析的目的，然後從系統中刪除資料。",
        },
        {
          label:
            "總體來看，cookie 説明我們可以為您提供更好的網站服務，以使我們能夠了解哪些網頁對您有用，和你所不需要的。在 Cookie 中絕不允許我們訪問您的電腦或任何有關你未授權的私人隱私，除了你選擇與我們共用的資料。你可以選擇接受或拒絕 cookie。大多數 web 瀏覽器可自動接受 cookie，但你通常可以修改您的瀏覽器設置拒絕 cookie，如果你喜歡。這可能會阻止您充分利用這個網站和購物的使用。",
        },
      ],
    },
    {
      label: "與其他網站的連結",
      li: [
        {
          label:
            "我們的網站可能包含指向其他網站的利益。然而，一旦您使用這些連結，離開我們的網站，你應該注意到我們沒有任何控制其他網站的權利和能力。因此，在您離開我們的網站後，我們無法負責保護和任何的隱私資訊，在您提供同時訪問這樣的其他網站，這類網站或其他網站不受本隱私權聲明。你應該小心謹慎，並看看隱私權聲明是否適用於有問題的網站，請您注意自己在網路上的交易安全。",
        },
      ],
    },
    {
      label: "控制您的個人資訊",
      li: [
        {
          label: "您可以選擇以下的方式，並限制收集或使用您的個人資訊：",
        },
        {
          label:
            "每當你被要求填寫一張表格在網站上，尋找選擇框，您可以按一下並指示您需不需要用於直接促銷和其他使用的目的，並對任何人的資訊。",
        },
        {
          label:
            "如果您先前已同意我們使用您的個人資訊用於直接促銷和其他使用的目的，在當你在任何時候改變主意，你可以填寫電子郵件我們取得聯繫。",
        },
        {
          label:
            "我們不會出售、 分發或租賃您的個人資訊給協力廠商，除非正式取得您的許可權、聲明或法律要求並得到授權。我們只能夠使用您的個人資訊向您發送有關我們認為您可能感興趣的商品和這個網站的廣告宣傳，除非在您告知授權，否則我們不希望這種情況發生在協力廠商的商品促銷資訊上。",
        },
      ],
    },
  ];
  function getLi(item) {
    if (item.li) {
      return (
        <ul>
          {item.li.map((item2) => (
            <>
              <li>{item2.label}</li>
              {item2.li ? getLi(item2) : null}
            </>
          ))}
        </ul>
      );
    } else {
      return null;
    }
  }

  return (
    <div>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Button
          color="primary"
          onClick={handleClickOpen}
          style={{ textAlign: "right" }}
        >
          網站服務條款及隱私政策
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>隱私權條款</DialogTitle>
        <DialogContent>
          {contentData.map((item, index) => (
            <DialogContentText key={index} className={classes.ul}>
              {item.label}
              {getLi(item)}
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
