import React from "react";
import { Typography, Grid, makeStyles, Box } from "@material-ui/core";

export default function FAQPage() {
  const useStyles = makeStyles((theme) => ({
    box: {
      height: "auto",
      "& .MuiTypography-h4": {
        display: "inline-block",
        fontWeight: "bold",
        borderBottom: "3px solid #50A4DE",
        margin: "50px auto",
      },
    },
  }));

  const faqList = [
    {
      title: "Q:如何成為會員？",
      answer: [
        "具備中華民國國籍並年滿20歲具完全行為能力，完成手機驗證後，閱讀並同意「會員隱私條款」及「會員權益條款」，依操作指示便可成為會員。",
      ],
    },
    {
      title: "Q:該如何確認我的訂單？",
      answer: [
        "您可點選購物中心上方之「訂單查詢」中查看最近的交易記錄，可藉由交易狀態確認交易是否完成。",
      ],
    },
    {
      title: "Q:我買虛擬商品成功，但忘記抄下卡號密碼了，該如何處理？",
      answer: [
        "您可至「訂單查詢」查詢您的交易記錄，交易成功部份可點選查詢卡密字樣查詢所購買虛擬商品之卡號及密碼。",
      ],
    },
    {
      title: "Q:【統一發票】索取流程說明",
      answer: [
        "根據財政部令 台財稅字第0952400194 號『電子發票實施作業要點』，於本網站消費開立之統一發票將不主動寄送，如需正本請至訂單查詢頁 申請，並將發票號碼上傳至政府平台，供民眾自由查詢。(本作業規定全文)本公司依據『網路購物開立統一發票交付非營業人作業規定』。",
      ],
    },
    {
      title: "Q: 在這裡購買MYCARD如何把點數存入MYCARD會員帳號?",
      answer: [
        "回到MYCARD https://app.mycard520.com.tw/ 網站",
        "登入會員>會員轉點>選擇遊戲>輸入安全代碼>大功告成!",
      ],
    },
    {
      title: "Q: MyCard點數卡共有幾種面額？",
      answer: [
        "MyCard點數卡目前提供的面額有30點、50點、90點、150點、170點、300點、350點、400點、450點、500點、750點、1000點、1150點、1490點、1690點、2000點、2500點、3000點、3290點、5000點、10000點。",
        "※台灣淘米系列遊戲，目前點數僅配合MyCard點數卡1000點以下面額。",
        "本網站未列之點數面額請至官網購買",
      ],
    },
    {
      title: "Q: 點數卡錯誤儲該怎麼辦？ 可以取消退費嗎？",
      answer: [
        "需先釐清您購買的點卡品牌 ; 後依據各家處理方式急時間有些許差異 ; 詳請聯繫本站客服人員!",
      ],
    },
    {
      title:
        "Q:手機遊戲中點選MyCard儲值後，出現「當前APP尚未支援OOOOOO功能」？",
      answer: [
        "若您的手機為安卓系統並且系統版本為6.0以下，則該應用程式並未支援此相機掃描功能，請您改用手動輸入卡號密碼方式即可。 若您的手機為安卓系統並且系統已升級至6.0以上，請您至手機中進行以下調整『設定應用程式當前APP權限』將相機權限打開即可。若列表中無相機權限，則為該應用程式並未支援相機功能，請以手動輸入卡號密碼方式即可。",
      ],
    },
  ];

  const classes = useStyles();
  return (
    <Grid
      className={classes.box}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={11} style={{ marginBottom: "50px" }}>
        <Box display="flex" justifyContent="center">
          <Typography variant="h4">FAQ</Typography>
        </Box>
        {faqList.map((item) => {
          return (
            <Box mb={4}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {item.title}
              </Typography>
              {item.answer.map((answer) => {
                return <Typography variant="h6">{answer}</Typography>;
              })}
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
}
