import React from "react";
import { Typography, Grid, makeStyles, Container } from "@material-ui/core";

export default function AboutPage() {
  const useStyles = makeStyles((theme) => ({
    box: {
      height: "80vh",
      textAlign: "center",
      "& .MuiTypography-h4": {
        display: "inline-block",
        fontWeight: "bold",
        borderBottom: "3px solid #50A4DE",
        margin: "50px auto",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h4">關於我們</Typography>
          <Typography
            gutterBottom
            variant="body1"
            style={{ lineHeight: "1.8" }}
          >
            BesBuy 百適買點數卡販售網、網站成立兩年以來不斷增加點卡合作廠商 ;
            <br />
            公司初期以美妝雜貨等商品開始。負責人由設計師以及工程師組成 ;<br />
            擁有多年的網路技術經驗。成立後因工程師熱愛遊戲之故因緣際會下有機會代理遊戲點數卡
            ;<br />
            爾後漸調整商品類型以及走向 ;
            最後在2020年末全面販售點卡目前已經用市場最有競爭力的價格提供Garena/Mycard/Gash...等點數卡
            ; 未來將擴大提供洽談中的DMM/任天堂/ BitCash/iTunes Gift
            Card/以及WebMoney 等數位點卡 !!
          </Typography>
          <Typography variant="body1">
            我們的目標是讓玩家們在 BesBuy
            隨時隨地~可以一次購得所有國家的各種遊戲入場券!
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
