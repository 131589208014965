import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  TextareaAutosize,
  Button
} from '@material-ui/core'

export default function ContactUsPage() {
  const useStyles = makeStyles((theme) => ({
    box:{
        height: "auto",
        textAlign:'center',
        '& .MuiTypography-h4':{
          display:'inline-block',
          fontWeight:'bold',
          borderBottom:'3px solid #50A4DE',
          margin:'50px auto'
        }
    },
    }));
  const classes = useStyles();
    return (
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={5} md={5}>
          <Typography variant="h4">聯絡我們</Typography>
          <Typography variant="body1" style={{ marginBottom: "50px" }}>
          公司登記名稱：奇麗科技股份有限公司
            <br />
            統一編號：25080162
            <br />
            客服時間 : 星期一&ensp;～&ensp;星期六，10:00AM～19:00 PM
            <br />
            EMAIL : besbuyservice@gmail.com
            <br />
          </Typography>
          {/* <Grid item xs={11} style={{ margin: "0px auto 30px" }}>
            <TextareaAutosize minRows={10} style={{ width: "100%",resize:'none' }} />
          </Grid>
          <Grid item xs={11} style={{ margin: "0px auto 150px" }}>
            <Button color="primary" variant="contained" style={{color:'#fff'}}>
              送出
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
    );
}