import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  makeStyles,
  Typography,
  InputLabel,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
  useWatch,
} from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { ReactComponent as LogoIcon } from "../svgs/logo.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useAlert } from "../components/Alert";
import LoadingModal from "../components/LoadingModal";
import {
  verifyPattern,
  passwordPattern,
  mobilePattern,
} from "../components/unit";

const SEND_VERIFICATIONCODE = gql`
  mutation sendVerificationCode($mobile: String!) {
    sendVerificationCode(mobile: $mobile)
  }
`;

const CHECK_VERIFICATIONCODE = gql`
  mutation checkVerificationCode($mobile: String, $verificationCode: String!) {
    checkVerificationCode(mobile: $mobile, verificationCode: $verificationCode)
  }
`;

const RESET_USERPASSWORD = gql`
  mutation resetUserPassword(
    $mobile: String!
    $verificationCode: String!
    $password: String!
  ) {
    resetUserPassword(
      mobile: $mobile
      verificationCode: $verificationCode
      password: $password
    )
  }
`;

export default function ForgotPasswordPage() {
  const resetUserPasswordForm = useForm({
    defaultValues: {
      mobile: "",
      verificationCode: "",
      password: "",
      repassword: "",
      verificationCodeSend: false,
    },
  });
  const { control } = resetUserPasswordForm;
  const verificationCodeSend = useWatch({
    control,
    name: "verificationCodeSend",
  });

  return (
    <div>
      <FormProvider {...resetUserPasswordForm}>
        {verificationCodeSend ? <ReSetPassword /> : <VerifyMobile />}
      </FormProvider>
    </div>
  );
}

function VerifyMobile() {
  const Alert = useAlert();
  const isPhone = useMediaQuery("(max-width:500px)");
  const useStyles = makeStyles((theme) => ({
    box: {
      height: "100vh",
    },
    input: {
      height: "40px",
      padding: "0px",
      backgroundColor: "white",
    },
    labeltext: {
      color: "black",
      marginTop: "10px",
      marginBottom: "10px",
      fontSize: "14px",
      fontWeight: 400,
    },
  }));
  const classes = useStyles();
  const { control, setValue, getValues, handleSubmit } = useFormContext();
  const [verifying, setVerifying] = useState(false);

  function _sendVerificationCode() {
    if (!getValues("mobile")) {
      return Alert.notify("手機號碼未填寫！");
    }
    if (!mobilePattern.test(getValues("mobile"))) {
      return Alert.notify("手機號碼格式錯誤！");
    }
    sendVerificationCode({
      variables: { mobile: getValues("mobile") },
    });
  }

  const [sendVerificationCode, { loading }] = useMutation(
    SEND_VERIFICATIONCODE,
    {
      onCompleted({ sendVerificationCode }) {
        if (sendVerificationCode) {
          return Alert.notify("驗證碼發送成功！", () => setVerifying(true));
        } else {
          return Alert.notify("驗證碼發送失敗，請重新嘗試！");
        }
      },
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    }
  );

  const [checkVerificationCode, { loading: checkVerificationCodeLoading }] =
    useMutation(CHECK_VERIFICATIONCODE, {
      onCompleted({ checkVerificationCode }) {
        if (checkVerificationCode) {
          setValue("verificationCodeSend", true);
        } else {
          return Alert.notify("驗證碼輸入錯誤！");
        }
      },
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    });

  function _submit(data) {
    checkVerificationCode({
      variables: {
        mobile: data.mobile,
        verificationCode: data.verificationCode,
      },
    });
  }

  return (
    <Grid>
      <LoadingModal loading={loading || checkVerificationCodeLoading} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid container item spacing={1} xs={11} sm={6} md={5}>
          <Grid
            item
            xs={12}
            style={{
              display: isPhone ? "none" : "flex",
              justifyContent: "center",
              margin: "50px 0px",
            }}
          >
            <LogoIcon style={{ width: "300px", height: "64px" }} />
          </Grid>
          <Grid
            item
            style={{ display: "flex", justifyContent: "center" }}
            xs={12}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: 700 }}
              color="primary"
              gutterBottom
            >
              忘記密碼
            </Typography>
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>電話</InputLabel>
            <Controller
              name="mobile"
              control={control}
              rules={{
                required: !getValues("verificationCodeSend")
                  ? "電話為必填"
                  : false,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入電話"
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <Button
                        color="primary"
                        variant="contained"
                        style={{
                          height: 40,
                          width: "45%",
                          marginLeft: "0.5em",
                          color:'#fff'
                        }}
                        onClick={_sendVerificationCode}
                        disabled={error}
                      >
                        電話驗證
                      </Button>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>驗證碼</InputLabel>
            <Controller
              name="verificationCode"
              control={control}
              rules={{
                required: !getValues("verificationCodeSend")
                  ? "驗證碼未輸入"
                  : false,
                pattern: {
                  value: verifyPattern,
                  message: "驗證碼格式不正確！",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder={verifying ? "驗證碼" : "請先按下手機驗證"}
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  disabled={!verifying}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit(_submit)}
              style={{ marginTop: "100px", color:'#fff' }}
              fullWidth
            >
              下一步
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function ReSetPassword() {
  const Alert = useAlert();
  const isPhone = useMediaQuery("(max-width:500px)");
  const useStyles = makeStyles((theme) => ({
    box: {
      height: "100vh",
    },
    input: {
      height: "40px",
      padding: "0px",
      backgroundColor: "white",
    },
    labeltext: {
      color: "black",
      marginTop: "10px",
      marginBottom: "10px",
      fontSize: "14px",
      fontWeight: 400,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const { control, getValues, handleSubmit } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const [resetUserPassword, { loading: resetUserPasswordLoading }] =
    useMutation(RESET_USERPASSWORD, {
      onCompleted({ resetUserPassword }) {
        if (resetUserPassword) {
          history.replace("/log-in");
          return Alert.notify("更改密碼成功！");
        } else {
          return Alert.notify("更改密碼發生錯誤，請重新嘗試！");
        }
      },
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    });

  function _submit(data) {
    resetUserPassword({
      variables: {
        mobile: data.mobile,
        verificationCode: data.verificationCode,
        password: data.password,
      },
    });
  }

  return (
    <Grid>
      <LoadingModal loading={resetUserPasswordLoading} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid container item spacing={1} xs={11} sm={6} md={5}>
          <Grid
            item
            xs={12}
            style={{
              display: isPhone ? "none" : "flex",
              justifyContent: "center",
              margin: "50px 0px",
            }}
          >
            <LogoIcon style={{ width: "300px", height: "64px" }} />
          </Grid>
          <Grid
            item
            style={{ display: "flex", justifyContent: "center" }}
            xs={12}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: 700 }}
              color="primary"
              gutterBottom
            >
              忘記密碼
            </Typography>
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>密碼</InputLabel>
            <Controller
              name="password"
              control={control}
              rules={{
                required: getValues("verificationCodeSend")
                  ? "密碼為必填"
                  : false,
                pattern: {
                  value: passwordPattern,
                  message: "密碼格式錯誤，需包含英文以及數字！",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入密碼"
                  type={showPassword ? "text" : "password"}
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          size="small"
                          onClick={() => setShowPassword((e) => !e)}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>再次輸入密碼</InputLabel>
            <Controller
              name="repassword"
              control={control}
              rules={{
                required: getValues("verificationCodeSend")
                  ? "未輸入密碼"
                  : false,
                validate: {
                  value: (value) =>
                    getValues("password") === value || "密碼不一致",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請再次輸入密碼"
                  type={showRePassword ? "text" : "password"}
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          size="small"
                          onClick={() => setShowRePassword((e) => !e)}
                        >
                          {showRePassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit(_submit)}
              style={{ marginTop: "100px" , color:'#fff'}}
              fullWidth
            >
              完成
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
