import React from "react";
import { Typography, Grid, makeStyles, Container } from "@material-ui/core";

export default function AnniversarySaleEventPage() {
  const useStyles = makeStyles((theme) => ({
    box: {
      textAlign: "left",
      "& .MuiTypography-h4": {
        display: "inline-block",
        fontWeight: "bold",
        borderBottom: "3px solid #50A4DE",
        margin: "50px auto",
      },
      "& .MuiTypography-h6": {
        margin: "1em",
      },
      "& .MuiTypography-body1": {
        marginLeft: "3em",
        lineHeight: "2em",
      },
      marginBottom: "5em",
    },
  }));
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h4">歡慶百適買 3週年生日慶</Typography>
          <img
        src="/assets/images/Home/banner7.jpg"
        style={{ width: "100%", height: "400px", objectFit: "contain" }}
      />
          <Typography variant="h6">活動期間：</Typography>
          <Typography variant="body1">
            2023年6月1日~2023年10月31日23：59PM止（截止時間以官網系統主機時間為準）
          </Typography>
          <Typography variant="h6">活動說明：</Typography>
          <Typography variant="body1">
            <ol>
              <li>
                於活動期間內，凡於百適買購物網上單筆消費滿1,000元以上，即可參加抽獎。
              </li>
              <li>單筆消費僅可抽獎乙次，僅具一次中獎資格，且不予重複中獎。</li>
              <li>
                本活動每位參加者於每月抽獎僅有一次得獎機會。舉例：6月得獎者A獲得PS5，當月不再重複獲得其他獎品。
              </li>
            </ol>
          </Typography>
          <Typography variant="h6">活動獎項：</Typography>
          <Typography variant="body1">
            <ol>
              <li>第一獎：Gogoro S1電動摩托車</li>
              <li>SONY PlayStation® 5主機</li>
              <li>Nintendo Switch主機</li>
            </ol>
            ※以上獎品每月各抽出１名，共抽獎五個月（每月１次）
          </Typography>
          <Typography variant="h6">抽獎日期：</Typography>
          <Typography variant="body1">
            <ol>
              <li>
                2023年6月1日起，每月將抽出指定獎項。每月收獎時間為隔月的５號。舉例：2023年7月5日抽出6月得獎者，2023年8月5日抽出7月得獎者，以此類推。
              </li>
              <li>得獎名單將本公司活動小組於抽獎後5個工作日內以電話通知。</li>
            </ol>
          </Typography>
          <Typography variant="h6">兌獎方式：</Typography>
          <Typography variant="body1">
            <ol>
              <li>
                本公司活動小組以電話通知後，將會以電子郵件寄發中獎通知函，請得獎者於收到後10個工作日內回覆。
              </li>
              <li>
                若有所需檢附之相關文件，掛號郵寄寄回(以郵戳日期為憑)【奇麗科技(股)公司
                / 110 台北市信義區信義路五段五號2C-10 百適買週年慶
                活動小組收】，續可辦理兌獎作業。逾期者或文件不齊全或不正確者將視同放棄得獎資格，得獎名額將不進行遞補。
              </li>
              <li>
                得獎者將依中華民國稅法規定，獎項金額超過新台幣1,000元應列入個人所得申報。中頭獎者除前述文件外，如獎品獎項價值超過新台幣20,000元，依法中獎者需先行繳納10%稅金(稅額以市價計算)，並由執行單位代收代繳，使得領取活動獎品。以上兌獎所需文件，缺一不可，如有欠缺、不完整、不正確與活動辦法不符，恕無法兌獎。
              </li>
              <li>
                如遇國定假日或天災等不可抗力因素，主辦單位及/或活動執行單位有權變更抽獎日期及公告日期。
              </li>
            </ol>
          </Typography>
          <Typography variant="h6">獎項寄發：</Typography>
          <Typography variant="body1">
            <ol>
              <li>
                本公司活動小組以電話通知後，將會以電子郵件寄發中獎通知函。活動小組收到得獎者相關中獎及領獎所需資料，驗證無誤後，即會進行獎品兌獎作業。
              </li>
              <li>至指定日期為止，未完成兌獎程序者，視同放棄領獎資格。</li>
              <li>
                若有所需檢附之相關文件，掛號郵寄寄回（以郵戳日期為憑）【奇麗科技(股)公司
                / 110 台北市信義區信義路五段五號2C-10 百適買週年慶
                活動小組收】，續可辦理兌獎作業。逾期者或文件不齊全或不正確者將視同放棄得獎資格，得獎名額將不進行遞補。
              </li>
              <li>
                中獎獎項以本公司活動小組以電話通知之內容為主，詳見活動獎項說明。
              </li>
            </ol>
          </Typography>
          <Typography variant="h6">注意事項：</Typography>
          <Typography variant="body1">
            <ol>
              <li>
                百適買週年慶活動(以下稱本活動)係由奇麗科技股份有限公司(下稱主辦單位)所舉辦並執行，消費者一般個人資料(包括：姓名、地址、電話、e-mail帳號、身分證正反面影本)之蒐集、處理及利用並為連絡及寄送獎項之用(以下簡稱「活動執行單位」)。消費者於參加本活動之同時，即同意接受本活動之活動辦法與注意事項之規範，並同意主辦單位或活動執行單位蒐集、處理及利用參加人之姓名、地址、電話、e-mail帳號以及身分證正反面影本等之一般個人資料，如不同意，恕無法參加活動或獎項寄送。消費者如有違反，主辦單位或活動執行單位得取消其參加及得獎資格，並得就因此所產生之損害，向該消費者請求損害賠償。
              </li>
              <li>
                本活動如有任何因電腦、網路、電話、技術、郵寄或其他不可歸責於主辦單位或活動執行單位之事由，而使參與本活動者所寄出之資料有延遲、遺失、錯誤、無法辨識或毀損所導致資料無效之情況，贈獎資格視同放棄，主辦單位或活動執行單位不負任何法律責任，參與本活動者或得獎者亦不得因此異議。
              </li>
              <li>
                活動參與者應保證填寫或提出之資料均為真實，且未冒用或盜用任何第三人之資料，如冒用或盜用任何第三人之資料之法律責任由活動參與者自行負責，概與主辦單位或活動執行單位無關。如查獲不實，主辦單位或活動執行單位除撤銷其得獎資格，並無提供所獲獎項之義務，若獎項業經配送，並有向中獎者請求懲罰性賠償金及相關行政費用與他損害賠償之權利。
              </li>
              <li>
                所有中獎者需於活動兌獎指定日期前詳實填妥並回寄中獎收據、身分證正反面影本、中獎發票證明、應繳稅者應提供完稅之匯款證明，方可兌獎。主辦單位或活動執行單位對於中獎者所檢附之兌獎文件有最終審核及詮釋權。
              </li>
              <li>
                中獎者若查核發現有退貨的紀錄，視同自動放棄中獎資格，主辦單位或活動執行單位將不另行通知。
              </li>
              <li>
                本活動僅限居住於台灣、金門、澎湖、馬祖地區之中華民國國民參加，獎品寄送地址限於台灣、金門、澎湖、馬祖地區。
              </li>
              <li>
                消費者若以惡意之電腦程式或其他明顯違反活動公平性之方式，意圖混淆或影響活動結果者，一經主辦單位或活動執行單位查覺，可立即取消消費者之得獎資格，並可追回獎品。
              </li>
              <li>
                中獎者若未滿20歲，除中獎者之身分證正反面影本外，需另附法定代理人之身分證正反面影本（或監護人）之身分證正反面影本；若未成年人無國民身分證者，須提供中獎者戶口名簿影本或戶籍謄本正本（須為3個月內核發之版本）。
              </li>
              <li>
                本活動獎品以實物為準，中獎人不得要求折抵現金、退換、轉售或折換其他物品。主辦單位或活動執行單位保有修改或變更活動方式及更換活動贈品之權利。本活動獎品如遇有缺貨或不可抗力之因素無法提供時，主辦單位或活動執行單位保留更換等值贈品或現金的權利。
              </li>
              <li>
                依中華民國稅法規定，獎項金額若超過新台幣1,000元，得獎人須提供身份證正、反面影本，以利申報中獎所得，並請依規定填寫並繳交相關中獎收據。得獎者若無法配合，則視為自動棄權，不具得獎資格。獎項價值如超過新台幣20,000元者，中獎者需依法繳交機會中獎所得稅10%。獎項價值在1,000元以上未達20,000元者不需預先扣繳，但主辦單位及活動執行單位仍需於依法申報中獎所得並依所得稅憑單免填發作業辦理，中獎人得於申報個人綜合所得稅前，以政府核可之電子憑證線上或至國稅局臨櫃取得憑單資料即可。如果得獎者不願意給付得獎商品之稅額或不願意提出身分證明，則視為得獎者自動棄權，不具得獎資格。
              </li>
              <li>
                除上述注意事項，如有未盡事宜，主辦單位或活動執行單位擁有一切修改及取消本活動的權利而不作事前通知，亦有權對本活動之所有事宜做出解釋或裁決及變更活動獎品及辦法之權利，任何變更內容及其他詳細注意事項，則公告於活動網站。
              </li>
              <li>
                上述條款之準據法為台灣地區境內法律，相關爭議應以台北地方法院為第一審管轄法院。
              </li>
              <li>
                參加者參加本活動之同時，將視為同意接受本活動所有內容及細則之規範，如有違反本活動注意事項之行為，活動小組得取消其兌換資格，並對於任何破壞本活動之行為保留法律追訴權。
              </li>
              <li>
                百適買週年慶活動 活動小組客服信箱：besbuyservice@gmail.com
              </li>
              <li>活動詳情以此活動網頁公告為準。</li>
            </ol>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
