import React, { createElement, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default function PrivacyPage() {
  return (
    <div>
      <PrivacyContent />
    </div>
  );
}

function Title({ title }) {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    title: {
      fontWeight: "bold",
      paddingBottom: "10px",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
  });
  const classes = useStyles();
  return (
    <Box my={6} display="flex" justifyContent="center">
      <Typography variant={isTablet ? "h5" : "h4"} className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
}

function SubTitle({ subTitle }) {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    title: {
      fontWeight: "bold",
      paddingBottom: "10px",
    },
  });
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h6" className={classes.title}>
        {subTitle}
      </Typography>
    </Box>
  );
}
function PrivacyContent() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({});
  const classes = useStyles();
  const privacyInfo = [
    {
      title: "一、基本原則",
      content: [
        "奇麗科技股份有限公司(以下稱本公司)非常重視顧客的隱私權及個人資料的保護，為了保護顧客隱私並遵循「個人資料保護法」及相關法令之規定，因此制定本隱私權保護政策，以避免顧客個人資料之遺失、洩漏、竊取、竄改等情事發生。本隱私權保護政策之內容如下供您參考。",
      ],
    },
    {
      title: "二、個人資料保護方針",
      content: [
        "保護顧客的個人隱私是本公司重要的經營理念，本公司將遵守以下個人資料保護方針來提供給顧客最優質的服務：",
        "BesBuy百適買點數卡販售網 (以下稱本販售網)向顧客蒐集而保有的個人資料以及本公司經由正當程序向第三方取得而保有的個人資料，將供本公司按原來向顧客說明之目的與範圍進行利用。本公司未經顧客同意之下，絕不會將其個人資料公開、提供給予任何與本販售網無關之第三人，或為特定目的以外之利用。請顧客在使用完本販售網所提供的各項服務功能後，務必記得登出帳戶，若是與他人共享電腦或使用公共電腦，切記要關閉瀏覽器視窗。",
      ],
    },
    {
      title: "三、個人資料的蒐集、處理及利用方式",
      content: [
        "1.蒐集目的",
        "本公司蒐集個人資料之目的在於本販售網之電子商務服務、商品銷售、契約管理、確認及履行與顧客之交易內容、商品之配送(物流)及付款等服務(包含信用卡等金融交易授權)、商品及服務之說明、廣告行銷、顧客及會員管理與服務(包含會員相關權益通知)、贈獎活動、調查、統計與研究分析、資訊與資料庫管理、其他契約相關業務(包含售後服務)及履行法定義務有所必要者。",
        "2.蒐集之個人資料類別",
        "本公司於網站內蒐集的個人資料包括：",
        "(1)基本資料(包含訂購人及收件人)：姓名、性別、出生年月日、電子郵件信箱、聯絡電話、行動電話、地址、身分證字號、會員帳號及密碼(如為會員)等資料。",
        "(2)帳務資料：支付方式、金融帳戶之號碼、信用卡帳號、交易帳戶號碼、訂單編號、訂單商品明細、消費金額及其他交易資料等資料。",
        "3.利用期間、地區、對象及方式:",
        "(1)期間：顧客當事人要求停止使用或本販售網停止提供服務之日為止。",
        "(2)地區：顧客之個人資料將用於台灣地區。",
        "(3)利用對象及方式：顧客之個人資料蒐集除用於本販售網之客戶管理、客戶管理之檢索查詢等功能外，亦將利用於辨識身份、金流服務、物流服務、行銷廣宣等。本公司將不會向第三人揭露您的個人資料，但法令另有規定或經取得您書面同意者，不在此限。",
        "(4)顧客就個人資料之權利：本公司所蒐集個人資料之當事人，依個人資料保護法得對本公司行使以下權利：",
        "(A)查詢或請求閱覽。",
        "(B)請求製給複製本。",
        "(C)請求補充或更正。",
        "(D)請求停止蒐集、處理或利用。",
        "(E)請求刪除。",
        "如您欲行使上述權利，請與本公司客服連絡或是寄信至客服信箱：besbuyservice@gmail.com進行申請。客服人員將以適當的方式確認您是否確為當事人本人或有權代為行使權利之人，並於合理的期間及範圍內協助處理相關事宜。",
        "※若您查詢、請求閱覽個人資料或請求製給複製本，本公司得酌收必要成本費用。",
      ],
    },
    {
      title: "四、資料安全",
      content: [
        "為保障顧客的隱私及安全，本公司顧客的帳號資料會用密碼保護，只由經過授權的人員才能接觸您的個人資料，本公司會盡力以合理之技術及程序，保障所有個人資料之安全。",
      ],
    },
    {
      title: "五、Cookie之使用",
      content: [
        "為了提供最佳的服務，本販售網會使用cookie技術，以便於提供會員所需要的服務，若您不接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高即可，但若顧客關閉cookie有可能導致網站某些功能無法正常執行。",
      ],
    },
    {
      title: "六、未成年人保護",
      content: [
        "本網站並非特別為未成年人/兒童設計，未成年人使用本網站時，若同意本販售網蒐集、利用其個人資訊時，應在法定代理人或監護人之同意下為之。法定代理人或監護人得隨時請求本公司停止特定帳號及其相關之個人資料之蒐集、處理及利用行為。",
      ],
    },
    {
      title: "七、隱私權保護政策修訂",
      content: [
        "本公司將因應法令修正、社會經濟環境變遷及科技進步之因素，隨時修訂本隱私權政策之內容，修訂後的條款將刊登於本販售網上。",
        "當您正在使用本網站時，本私隱政策的設置使用於 BESBUY 百適買 並且進一步的保護你提供給我們的任何資訊。 BESBUY 百適買 致力於保護您的隱私。在您運作這個網站相關資料與購物時，可能會進一步的要求您提供某些相關資訊，您可以根據標示的拒絕存取，放心並正常的使用，提供的個資; 網站將只根據此份隱私權聲明宣告部分運用。",
        "BESBUY 百適買 我們會隨時不定期的更新這個頁面中的隱私權政策，並符合政府與法令相關的條件施行 ( 使用中華民國法令之保障權益 )。您應該檢查此頁以確保你是否滿意其中的任何更改。",
      ],
    },
    {
      title: "我們收集哪些資訊",
      content: [
        "我們可能會收集以下資訊：",
        "您提供的名字。",
        "包括您的電子郵件地址的連絡人資訊。",
        "統計資訊，如郵遞區號、 愛好和興趣。",
        "有關客戶調查和或提供的其他相關資料。",
        "我們收集您的 cookies 的詳盡清單，請參閱 Cookies 清單 章節。",
      ],
    },
    {
      title: "關於我們收集到的資訊",
      content: [
        "我們需要該資訊以瞭解您購物的需求，為了提供您更好的服務和愉快的購物體驗，特別是出於以下原因：",
      ],
    },
    {
      title: "內部記錄保存。",
      content: [
        "我們可能會使用這些資訊，以方便進一步改進我們的商品資訊和服務。",
        "我們可能會定期發送有關新產品的促銷電子郵件、特別優惠或其他我們認為您可能會發現的購物樂趣，並使用您提供的電子郵件地址寄送相關資訊。",
        "我們還可能使用您的資訊與您聯繫，以市場研究為目的。我們會透過電子郵件與您聯繫。我們會根據你的興趣和需求相關資訊來定制本網站相關活動內容。",
      ],
    },
    {
      title: "交易安全",
      content: [
        "我們會致力確保您的資訊安全。為了防止未經授權的訪問或披露，我們已在適當的商品物件、 電子系統閘道和管理程式上做好防範措施，以確保並保障及保護我們線上收集的資訊以及您的交易過程。",
      ],
    },
    {
      title: "我們如何使用 cookie",
      content: [
        "Cookie 是一個極小的檔案，是一個要求放置在您的電腦硬碟上的許可權。一旦你同意，該檔將添加在 cookie 可以説明分析網站流量，或讓你知道當你訪問一個特定的網站。Cookie 允許 web 應用程式回應你作為一個個體。Web 應用程式可以定制您的需求操作，喜歡和不喜歡通過收集和記憶您的首選項目相關的資訊。",
        "我們使用 cookie 流量控制，以確定哪些頁面正在使用。這可以説明我們分析網頁流量資料和改善我們的網站，以適應客戶的需求。我們僅將此資訊用於統計分析的目的，然後從系統中刪除資料。",
        "總體來看，cookie 説明我們可以為您提供更好的網站服務，以使我們能夠了解哪些網頁對您有用，和你所不需要的。在 Cookie 中絕不允許我們訪問您的電腦或任何有關你未授權的私人隱私，除了你選擇與我們共用的資料。你可以選擇接受或拒絕 cookie。大多數 web 瀏覽器可自動接受 cookie，但你通常可以修改您的瀏覽器設置拒絕 cookie，如果你喜歡。這可能會阻止您充分利用這個網站和購物的使用。",
      ],
    },
    {
      title: "與其他網站的連結",
      content: [
        "我們的網站可能包含指向其他網站的利益。然而，一旦您使用這些連結，離開我們的網站，你應該注意到我們沒有任何控制其他網站的權利和能力。因此，在您離開我們的網站後，我們無法負責保護和任何的隱私資訊，在您提供同時訪問這樣的其他網站，這類網站或其他網站不受本隱私權聲明。你應該小心謹慎，並看看隱私權聲明是否適用於有問題的網站，請您注意自己在網路上的交易安全。",
      ],
    },
    {
      title: "控制您的個人資訊",
      content: [
        "您可以選擇以下的方式，並限制收集或使用您的個人資訊：",
        "每當你被要求填寫一張表格在網站上，尋找選擇框，您可以按一下並指示您需不需要用於直接促銷和其他使用的目的，並對任何人的資訊。",
        "如果您先前已同意我們使用您的個人資訊用於直接促銷和其他使用的目的，在當你在任何時候改變主意，你可以填寫電子郵件我們取得聯繫。",
        "我們不會出售、 分發或租賃您的個人資訊給協力廠商，除非正式取得您的許可權、聲明或法律要求並得到授權。我們只能夠使用您的個人資訊向您發送有關我們認為您可能感興趣的商品和這個網站的廣告宣傳，除非在您告知授權，否則我們不希望這種情況發生在協力廠商的商品促銷資訊上。",
      ],
    },
  ];
  return (
    <Box>
      <Container maxWidth="lg">
        <Title title="隱私權保護政策" />

        <Box bgcolor="#fff" my={2} p={isTablet ? 2 : 4}>
          {privacyInfo.map((item) => {
            return (
              <>
                <Box my={isTablet ? 2 : 4}>
                  <Typography
                    gutterBottom
                    variant={isTablet ? "h6" : "h4"}
                    style={{ color: "#3facff" }}
                  >
                    {item.title}
                  </Typography>
                </Box>
                <ul
                  style={{
                    textIndent: isTablet ? "0em" : "2em",
                    listStyle: "none",
                    margin: "1em 0",
                  }}
                >
                  {item.content.map((subItem) => {
                    return (
                      <li style={{ margin: "0.7em 0" }}>
                        <Typography
                          gutterBottom
                          component="p"
                          variant={isTablet ? "caption" : "body1"}
                        >
                          {subItem}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          })}
        </Box>
      </Container>
    </Box>
  );
}
