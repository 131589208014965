import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box
} from '@material-ui/core'

export default function GuidePage() {
  const useStyles = makeStyles((theme) => ({
    box:{
        height: "auto",
        '& .MuiTypography-h4':{
          display:'inline-block',
          fontWeight:'bold',
          borderBottom:'3px solid #50A4DE',
          margin:'50px auto'
        }
    },
    }));
  const classes = useStyles();
    return (
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={11}>
          <Box display="flex" justifyContent="center" >
            <Typography variant="h4">購物說明</Typography>
          </Box>
          <Typography variant="h6" style={{fontWeight:'bold'}}>【點數卡開卡前說明】</Typography>
          <Typography variant="h6" style={{ marginBottom: "100px"}}>
            GASH點數卡(含熱感印紙)儲值密碼只會有數字「0」和「1」，不會有英文的「I」和「O」。
            <br />
            購買前請先確認面額及適用遊戲，商品一經儲值使用恕不接受退換貨。
            <br />
            站內各遊戲點數卡(含熱感印紙)儲值後如未收到點數，或遊戲問題，請電洽本公司客服。
            <br />
            點數卡僅得進行一次性之儲值，使用本點數卡經儲值，即表示完成點數卡所提供之各項線上遊戲服務或服務商品兌換；您儲值存入各合作廠商服務平台或遊戲帳號之點數，不得要求將儲值點數回復為序號、兌回現金或退費。
            <br />
            點數卡適用之線上遊戲服務或商品項目內容請見各平台官網。
            <br />
            點數卡外包裝一經拆封或刮開銀漆後恕不補發、退換，請妥善保存避免接觸高溫、沾水。
            <br />
            點數卡無解除分期付款功能，代買點數應先確認，避免詐騙。
            <br />
            提醒玩家！近日頻繁發生不具名歹徒竊取受害人社群帳號、密碼後，再以該帳號上線，假冒被害人身分與親朋好友閒話家常，建立熟悉感與信任度，並要求將遊戲點數卡的卡片序號、儲值密碼、手機號碼、任何簡訊密碼(認證碼)在通訊軟體或電話中告知不具名歹徒，進行詐騙。請玩家要提高警覺，也要提醒身邊親朋好友，勿輕易在電話或網路中，告知對方家中成員的個人資料，收到任何簡訊（認証碼）勿輕易回覆或將認証碼告知任何人，以免遭到歹徒詐騙而受害。遇有類似情節請先保持冷靜撥165反詐騙專線求證或報案。
            <br />
            產品售後服務及保固/退換貨資訊全依消基法[線上遊戲點數（卡）定型化契約]所訂。
            <br />
          </Typography>
          </Grid>
      </Grid>
    );
}