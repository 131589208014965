import React from "react";
import { useLocation } from "react-router-dom";

export default function PaymentPage() {
  const location = useLocation();
  return (
    <iframe
      frameborder="0"
      noresize="noresize"
      style={{
        position: "absolute",
        background: "transparent",
        width: "100%",
        height: "100%",
      }}
      src={location.state.url}
    />
  );
}
