import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box
} from '@material-ui/core'

export default function ReturnExChangePage() {
  const useStyles = makeStyles((theme) => ({
    box:{
        height: "auto",
        '& .MuiTypography-h4':{
          display:'inline-block',
          fontWeight:'bold',
          borderBottom:'3px solid #50A4DE',
          margin:'50px auto'
        }
    },
    }));
  const classes = useStyles();
    return (
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={11}>
          <Box display="flex" justifyContent="center" >
            <Typography variant="h4">退換貨服務</Typography>
          </Box>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold"}}
          >
            【點數卡商品爭議說明】
          </Typography>
          <Typography variant="h6" style={{ marginBottom: "30px"}}> 
            ◆如遇商品損壞或瑕疵，客服人員判斷可退貨後程序流程〔商品退貨流程〕
            <br />
            點數卡到貨，發現損壞或刮開後無法使用、由客服人員確認點數卡瑕疵
            <br />
            紀錄客服人員服務編號及時間，並以原包裝寄回
            <br />
          </Typography>
          <Typography variant="h6" style={{ marginBottom: "100px"}}>
            請在包裝內，提供以下資訊：
            <br />
            隨貨訂單編號
            <br />
            訂購人姓名
            <br />
            聯絡人電話
            <br />
            聯絡客服人員編號
            <br />
            退貨商品審核
            <br />
            (回遊戲卡原廠認證流程7-14工作天）
            <br />
            收到退款
            <br />★
            點數卡瑕疵品退換經過檢查與測試之後，若發現點數卡並無序號瑕疵，消費者仍必須支付所有發生之相關費用。
          </Typography>
        </Grid>
      </Grid>
    );
}