import React, {
  useEffect,
  useContext,
  createContext,
  useReducer,
  useMemo,
} from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
// ANCHOR 創立context
const AlertContext = createContext(() => {});
// ANCHOR alert預設設定
const defaultAlertData = {
  title: "通知",
  contentText: "",
  action: { text: "確認", onClick: () => {}, type: "contained" },
};
//ANCHOR 暫存上一筆資料，使畫面渲染符合視覺
let temporary = {
  title: defaultAlertData["title"],
  contentText: defaultAlertData["contentText"],
  actions: [defaultAlertData["action"]],
};
// ANCHOR alert的hook
export function useAlert() {
  const alertDataDispatch = useContext(AlertContext);
  const defaultAlertAction = defaultAlertData["action"];
  /**
   * @param {string} title 標題
   * @param {string} contentText 內容
   * @param {[{
   *    text: string,
   *    type: 'contained' | 'outlined' | 'text',
   *    onClick: () => void
   *  }]} actions 按鈕組
   */
  function alert(title, contentText, actions) {
    alertDataDispatch({
      type: "open",
      data: {
        title: title || defaultAlertData["title"],
        contentText: contentText || defaultAlertData["contentText"],
        actions: actions.map((item) => {
          return {
            text: item.text || defaultAlertAction["text"],
            onClick: item.onClick || defaultAlertAction["onClick"],
            type: item.type || defaultAlertAction["type"],
          };
        }),
      },
    });
  }
  /**
   * @param {string} contentText 內容
   * @param {() => void} action 按鈕行為
   * @param {string} text 按鈕文字
   */
  function notify(contentText, action, text) {
    alertDataDispatch({
      type: "open",
      data: {
        title: defaultAlertData["title"],
        contentText: contentText || defaultAlertData["contentText"],
        actions: [
          {
            text: text || defaultAlertAction["text"],
            type: defaultAlertAction["type"],
            onClick: action || defaultAlertAction["onClick"],
          },
        ],
      },
    });
  }
  return { alert, notify };
}
// ANCHOR alert的通用層
export function AlertProvider({ children }) {
  const alertDataInit = [];
  const [alertData, alertDataDispatch] = useReducer(
    alertDataReducer,
    alertDataInit
  );
  function alertDataReducer(state, action) {
    switch (action.type) {
      case "open":
        // NOTE 將每次alert的東西推進陣列
        return [...state, action.data];
      case "close":
        // NOTE 移除時從陣列過濾出來
        return state.filter((item, index) => index !== action.index);
      default:
        // NOTE 未知情況改為預設值
        return alertDataInit;
    }
  }
  useEffect(() => {
    // NOTE 暫存第一次的資料
    if (alertData.length === 1) {
      const { title, contentText, actions } = alertData[0];
      temporary["title"] = title;
      temporary["contentText"] = contentText;
      temporary["actions"] = actions;
    }
  }, [alertData]);
  const lastIndex = alertData.length - 1;
  return (
    <AlertContext.Provider value={alertDataDispatch}>
      {children}
      <AlertModal
        data={alertData[lastIndex]}
        onClose={() => alertDataDispatch({ type: "close", index: lastIndex })}
      />
    </AlertContext.Provider>
  );
}
function AlertModal({ data, onClose = () => {} }) {
  const switchData = useMemo(() => {
    // NOTE 沒有資料時顯示第一次暫存的資料來過渡畫面
    if (data) {
      return data;
    } else {
      return temporary;
    }
  }, [data]);
  return (
    <Dialog open={Boolean(data)} fullWidth maxWidth="xs">
      <DialogTitle>{switchData.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{switchData.contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {switchData.actions.map(({ text, onClick, type }, index) => (
          <Button
            key={`action-${index}`}
            color="primary"
            variant={type}
            onClick={() => {
              if (onClick) {
                onClick();
              }
              onClose();
            }}
          >
            {text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}
