import React from "react";
import { CircularProgress, Backdrop, makeStyles } from "@material-ui/core";

export default function LoadingModal({ loading = false }) {
  const useStyles = makeStyles((theme) => ({
    Loading: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  }));
  const classes = useStyles();
  return (
    <Backdrop open={loading} classes={{ root: classes.Loading }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
